import { RichText } from "prismic-reactjs";
import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { Text } from "../../components/Text/Text";
import { useSendMail } from "../../hooks/useSendMail";
import AcceleratorIcon from "../../images/calender.png";
import CheckIcon from "../../images/check.png";
import { color, typography } from "../../shared/style";
import htmlSerializer from "../SpaceConntainer/RichtextHelper/CustomText";
import Dropdown from "./Dropdown";
import useWindowSize from "../AcademyDetailContainer/useWindowSize";
import Modal from "../../components/Modal/modal";
import CheckImage from "../../images/checkout-check.png";
import { navigate } from "gatsby";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid rgba(35, 35, 39, 0.2)",
    cursor: "pointer",
    lineHeight: "26px",
    color: "#232327",
    fontWeight: state.isSelected && "bold",
    backgroundColor: state.isSelected && "#fff",
  }),
  control: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      cursor: "pointer",
      height: isFocused ? 65 : 65,
      borderRadius: isFocused ? 50 : 50,
      paddingLeft: 22,
      border: 0,
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      borderRadius: 32,
      paddingLeft: 20,
      paddingRight: 20,
      overflow: "hidden",
    };
  },

  indicatorsContainer: (styles, { isFocused }) => {
    return { ...styles, paddingRight: isFocused ? 26 : 26 };
  },
  indicatorSeparator: (styles) => {
    return { ...styles, width: 0 };
  },
};

const encode = (data) => {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

const FormAccelerator = ({ data, countries }) => {
  const { title, description } = data?.header;
  const [tags, setTags] = useState([]);
  const [tagError, setTagError] = useState(false);
  const { sendEmail } = useSendMail();
  const [isBusinessRegister, setIsBusinessRegister] = useState(false);

  const [width] = useWindowSize();
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [file, setFile] = useState();
  const [openSuccess, setOpenSuccess] = useState(false);

  const contryOptions = countries.map((co) => ({
    label: co.name,
    value: co.code,
  }));
  const handleOpenModal = useCallback(() => {
    setOpenSuccess(!openSuccess);
  }, [openSuccess, setOpenSuccess]);

  const handleGoback = () => {
    navigate(-1);
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  const readFile = (e) => {
    const fileInput = e.target.files[0];
    setFile(fileInput);
    getBase64(fileInput)
      .then((result) => {
        file["base64"] = result;
        file.name = fileInput.name;
        file.base64 = result;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitPayment = async (data, e) => {
    if (tags.length === 0) {
      setTagError(true);
    }
    const dataForm = {
      ...data,
      amount: data.amount,
      businessStage: data.businessStage.value,
      country: data.country.value,
      investment: data.investment.value,
    };

    const industries = tags.map((item) => item.tag).join(", ");
    const preferred = data.preferred.join(", ");
    // const result = await sendEmail({
    //   ...dataForm,
    //   industries,
    //   file,
    //   preferred,
    // });
    const sendData = {
      ...dataForm,
      industries,
      file,
      preferred,
    }
    const form = e.target;
    fetch('https://draperstartuphouse.com/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...sendData,
      }),
    }).then(() => console.log('Form successfully submitted')).catch((error) =>
      alert(error))


    // console.log(result);
    // if (result.status === 200) {
    //   setOpenSuccess(true);
    // }
  };

  const hanleGetTag = (value) => {
    if (value.length > 0) {
      setTagError(false);
    }
    setTags(value);
  };

  return (
    <WrapperSection>
      <Iframe 
      class="airtable-embed" 
      src="https://airtable.com/embed/shr4lidmnPdrTRoy3?backgroundColor=gray" 
      frameborder="0" 
      onmousewheel="" 
      width="100%" 
      height="3300" 
      ></Iframe>
      {/* <WrapperModal>
        <Modal open={openSuccess} handleOpenModal={handleOpenModal}>
          <BoxCheckSuccess>
            <WrapCheckboxImage>
              <DoneIcon src={CheckImage} alt="image"></DoneIcon>
            </WrapCheckboxImage>
            <TitleBoxCheckSuccess
              tag="h5"
              font={typography.type.code}
              color={color.netraul.black100}
              weight={typography.weight.regular1}
              align="center"
              size={typography.size.l1}
              lineHeight={36}
              capital="uppercase"
            >
              Send successful
            </TitleBoxCheckSuccess>
            <DescriptionBoxCheckSuccess
              font={typography.type.primary}
              color={color.netraul.black100}
              weight={typography.weight.normal}
              align="center"
              size={typography.size.s3}
              lineHeight={26}
            >
              Thank you for your confirmation, see you soon.
            </DescriptionBoxCheckSuccess>
            <ActionBoxCheckSuccess>
              <ButtonGoBack onClick={handleGoback} type="primary">
                Go back to Accelerator
              </ButtonGoBack>
            </ActionBoxCheckSuccess>
          </BoxCheckSuccess>
        </Modal>
      </WrapperModal>
      <HeaderWrap>
        <HeaderTitle
          tag="h3"
          font={typography.type.code}
          color={color.primary.blue}
          weight={typography.weight.regular1}
          align="center"
          size={typography.size.code}
          lineHeight={90}
          capital="uppercase"
        >
          {title && (
            <RichText render={title?.raw} htmlSerializer={htmlSerializer} />
          )}
        </HeaderTitle>
        <HeaderDescription
          tag="h5"
          font={typography.type.primary}
          color={color.secondary.danube}
          weight={typography.weight.bold}
          align="center"
          size={typography.size.m1}
          lineHeight={30}
        >
          <RichText render={description?.raw} htmlSerializer={htmlSerializer} />
        </HeaderDescription>
      </HeaderWrap>
      <Form id="accelerator-form" name="accelerator-form" onSubmit={handleSubmit(submitPayment)} method="POST" netlify-honeypot="bot-field" data-netlify="true">
        <input type="hidden" name="form-name" value="accelerator-form" />
        <BoxForm>
          <TitleBoxForm>
            My Personal information <TitleBoxFormStar>*</TitleBoxFormStar>
          </TitleBoxForm>
          <FormGroup>
            <Input
              type="text"
              {...register("fullname", { required: true })}
              placeholder="First and last name"
            ></Input>
            {errors?.fullname?.type === "required" && (
              <MessgeError>This field is required</MessgeError>
            )}
          </FormGroup>
          <FormGroup>
            <Input
              type="email"
              {...register("email", { required: true })}
              placeholder="Email"
            ></Input>
            {errors?.email?.type === "required" && (
              <MessgeError>This field is required</MessgeError>
            )}
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              {...register("phoneNumber", {
                required: true,
                pattern: /^\d+$/,
              })}
              placeholder="Phone number"
            ></Input>
            {errors?.phoneNumber?.type === "required" && (
              <MessgeError>This field is required</MessgeError>
            )}
            {errors?.phoneNumber?.type === "pattern" && (
              <MessgeError>This field must be number</MessgeError>
            )}
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              {...register("stressAddress", { required: true })}
              placeholder="Street address"
            ></Input>
            {errors?.stressAddress?.type === "required" && (
              <MessgeError>This field is required</MessgeError>
            )}
          </FormGroup>
          <GroupFormRow>
            <FormGroup>
              <Input
                type="text"
                {...register("city", { required: true })}
                placeholder="City"
              ></Input>
              {errors?.city?.type === "required" && (
                <MessgeError>This field is required</MessgeError>
              )}
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                {...register("state", { required: true })}
                placeholder="State"
              ></Input>
              {errors?.state?.type === "required" && (
                <MessgeError>This field is required</MessgeError>
              )}
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                {...register("zipCode", { required: true })}
                placeholder="Zip/Postal code"
              ></Input>
              {errors?.zipCode?.type === "required" && (
                <MessgeError>This field is required</MessgeError>
              )}
            </FormGroup>
          </GroupFormRow>
          <Controller
            name="country"
            control={control}
            {...register("country", { required: true })}
            render={({ field }) => (
              <Select
                placeholder="Country"
                styles={customStyles}
                classNamePrefix="react-select"
                {...field}
                options={contryOptions}
              />
            )}
          />
          {errors?.country?.type === "required" && (
            <MessgeError>This field is required</MessgeError>
          )}
        </BoxForm>
        <BoxForm>
          <TitleBoxForm>
            My preferred location and date{" "}
            <TitleBoxFormStar>*</TitleBoxFormStar>
          </TitleBoxForm>
          <GroupCheckbox className="col-6">
            <Label>
              <Input
                {...register("preferred", { required: true })}
                value="Austin, Texas, United States"
                type="checkbox"
              ></Input>
              <SpanCheckbox className="span-checkbox"></SpanCheckbox>
              <SpanLabelCheckbox>
                <SpanLabelCheckboxTitle>
                  Austin, Texas, United States
                </SpanLabelCheckboxTitle>
                <SpanLabelCheckboxDesc>
                  August 23 – September 6, 2021
                </SpanLabelCheckboxDesc>
              </SpanLabelCheckbox>
            </Label>
          </GroupCheckbox>
          <GroupCheckbox className="col-6">
            <Label>
              <Input
                {...register("preferred", { required: true })}
                value="Miami, Florida, United States"
                type="checkbox"
              ></Input>
              <SpanCheckbox className="span-checkbox"></SpanCheckbox>
              <SpanLabelCheckbox>
                <SpanLabelCheckboxTitle>
                  Miami, Florida, United States
                </SpanLabelCheckboxTitle>
                <SpanLabelCheckboxDesc>
                  August 14 – November 13, 2022
                </SpanLabelCheckboxDesc>
              </SpanLabelCheckbox>
            </Label>
          </GroupCheckbox>
          {errors?.preferred?.type === "required" && (
            <MessgeError>This field is required</MessgeError>
          )}
        </BoxForm>
        <BoxForm>
          <TitleBoxForm>
            My BUSINESS Information <TitleBoxFormStar>*</TitleBoxFormStar>
          </TitleBoxForm>
          <FormGroup>
            <Input
              type="text"
              {...register("companyName", { required: true })}
              placeholder="Company name"
            ></Input>
            {errors?.companyName?.type === "required" && (
              <MessgeError>This field is required</MessgeError>
            )}
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              {...register("companyWebsite", { required: true })}
              placeholder="Company website"
            ></Input>
            {errors?.companyWebsite?.type === "required" && (
              <MessgeError>This field is required</MessgeError>
            )}
          </FormGroup>
          <FormGroup>
            <TextArea
              rows="7"
              {...register("descriptionCompany", { required: true })}
              placeholder="Brief company description (1000-2000 words)"
            ></TextArea>
            {errors?.descriptionCompany?.type === "required" && (
              <MessgeError>This field is required</MessgeError>
            )}
          </FormGroup>
          <FormGroup>
            <GroupCheckbox className="col-12">
              <Label>
                <Input
                  {...register("registered")}
                  value={true}
                  type="checkbox"
                ></Input>
                <SpanCheckbox className="span-checkbox "></SpanCheckbox>
                <SpanLabelCheckbox>
                  This business is a registered legal entity in one of the
                  United States
                </SpanLabelCheckbox>
              </Label>
            </GroupCheckbox>
          </FormGroup>
          <FormGroup>
            <Dropdown hanleGetTag={hanleGetTag} />
            {tagError && <MessgeError>This field is required</MessgeError>}
          </FormGroup>
          <FormGroup>
            <Controller
              name="businessStage"
              control={control}
              {...register("businessStage", { required: true })}
              render={({ field }) => (
                <Select
                  placeholder="My business stage"
                  styles={customStyles}
                  classNamePrefix="react-select"
                  {...field}
                  options={[
                    { label: "Idea", value: "Idea" },
                    { label: "MVP", value: "MVP" },
                    { label: "Some Traction", value: "Some Traction" },
                    {
                      label: "Generating Revenue",
                      value: "Generating Revenue",
                    },
                  ]}
                />
              )}
            />
            {errors?.businessStage?.type === "required" && (
              <MessgeError>This field is required</MessgeError>
            )}
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              {...register("numberPeople", {
                required: true,
                pattern: /^\d+$/,
              })}
              placeholder="Number of people on founding team"
            ></Input>
            {errors?.numberPeople?.type === "required" && (
              <MessgeError>This field is required</MessgeError>
            )}
            {errors?.numberPeople?.type === "pattern" && (
              <MessgeError>This field must be number</MessgeError>
            )}
          </FormGroup>
          <FormGroup>
            <TextArea
              rows="7"
              {...register("informationFounders", { required: true })}
              placeholder="My co-founders’ names and roles"
            ></TextArea>
            {errors?.informationFounders?.type === "required" && (
              <MessgeError>This field is required</MessgeError>
            )}
          </FormGroup>
          <FormGroup>
            <Controller
              name="investment "
              control={control}
              {...register("investment", { required: true })}
              render={({ field }) => (
                <Select
                  placeholder={
                    width < 650
                      ? "Investment capital raised"
                      : "I’ve previously raised investment capital for this business (how much?)"
                  }
                  styles={customStyles}
                  classNamePrefix="react-select"
                  {...field}
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                    {
                      label: "Some (< US$ 100,000)",
                      value: "Some (< US$ 100,000)",
                    },
                    {
                      label: "Some (> US$ 100,000)",
                      value: "Some (> US$ 100,000)",
                    },
                  ]}
                />
              )}
            />
            {errors?.investment?.type === "required" && (
              <MessgeError>This field is required</MessgeError>
            )}
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              {...register("amount", { required: true })}
              placeholder={
                width < 650
                  ? "Investment capital needed"
                  : "I need invesment capital in the amount of"
              }
            ></Input>

            {errors?.amount?.type === "required" && (
              <MessgeError>This field is required</MessgeError>
            )}
          </FormGroup>
        </BoxForm>
        <BoxForm>
          <TitleBoxForm>
            MY Pitch Deck <TitleBoxFormStar>*</TitleBoxFormStar>
          </TitleBoxForm>
          <FormGroup>
            <Label>
              <Input
                {...register("file", { required: true })}
                type="file"
                accept=".pdf"
                name="file"
                onChange={(e) => readFile(e)}
              ></Input>
              <FileLable>
                <span>{file ? file.name : "Attach a PDF file"}</span>
                <FileIcon src={AcceleratorIcon}></FileIcon>
              </FileLable>
            </Label>
            {errors?.file?.type === "required" && (
              <MessgeError>This field is required</MessgeError>
            )}
          </FormGroup>
        </BoxForm>
        <BoxForm>
          <TitleBoxForm>
            This is Why I’m a good fit <TitleBoxFormStar>*</TitleBoxFormStar>
          </TitleBoxForm>

          <FormGroup>
            <TextArea
              rows="7"
              {...register("good", { required: true, maxLength: 2000 })}
              placeholder="Describe what makes you a good fit for the Draper Startup House Accelerator (1000-2000 words)"
            ></TextArea>
            {errors?.good?.type === "required" && (
              <MessgeError>This field is required</MessgeError>
            )}
            {errors?.good?.type === "maxLength" && (
              <MessgeError>Max length is 2000 characters</MessgeError>
            )}
          </FormGroup>

          <FormGroup>
            <TextArea
              rows="7"
              {...register("hope", { required: true, maxLength: 2000 })}
              placeholder="What are you hoping to gain from the Draper Startup House Accelerator? (1000-2000 words)"
            ></TextArea>
            {errors?.hope?.type === "required" && (
              <MessgeError>This field is required</MessgeError>
            )}
            {errors?.hope?.type === "maxLength" && (
              <MessgeError>Max length is 2000 characters</MessgeError>
            )}
          </FormGroup>
          <FormGroup>
            <TextArea
              rows="7"
              {...register("other", { required: true, maxLength: 2000 })}
              placeholder="Something else I want the selection committee to know about me (1000-2000 words)"
            ></TextArea>
            {errors?.other?.type === "required" && (
              <MessgeError>This field is required</MessgeError>
            )}
            {errors?.other?.type === "maxLength" && (
              <MessgeError>Max length is 2000 characters</MessgeError>
            )}
          </FormGroup>
        </BoxForm>
        <BoxForm>
          <TitleBoxForm>
            I heard about Draper Startup House Accelerator From
          </TitleBoxForm>
          {[
            { name: "Friend or colleague", value: "Friend or colleague" },
            { name: "Social media", value: "Social media" },
            { name: "Other", value: "Other" },
            { name: "DEN Slack channel", value: "DEN Slack channel" },
            { name: "Internet search", value: "Internet search" },
          ].map((item, index) => (
            <GroupCheckbox key={index}>
              <Label>
                <Input
                  {...register("connectBy")}
                  value={item.value}
                  type="checkbox"
                ></Input>
                <SpanCheckbox className="span-checkbox"></SpanCheckbox>
                <SpanLabelCheckbox>{item.name}</SpanLabelCheckbox>
              </Label>
            </GroupCheckbox>
          ))}
        </BoxForm>
        <GroupButton>
          <ButtonSubmit type="tertiary" id="submit">
            Send my application
          </ButtonSubmit>
        </GroupButton>
      </Form> */}
    </WrapperSection>
  );
};
// style="background: transparent; border: 1px solid rgb(204, 204, 204); opacity: 1; visibility: visible;"
const Iframe = styled.iframe`
  background: transparent;
  border: 1px solid rgb(204, 204, 204);
  opacity: 1;
  visibility: visible;
`

const ButtonGoBack = styled(Button)`
  padding: 20px 45px;
  border-radius: 40px;
`;

const ActionBoxCheckSuccess = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    margin-top: 40px;
    flex-direction: column-reverse;
  }
`;

const DescriptionBoxCheckSuccess = styled(Text)``;
const TitleBoxCheckSuccess = styled(Text)`
  padding: 0 0px 12px;
`;

const DoneIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const WrapCheckboxImage = styled.div`
  max-width: 109px;
  max-height: 109px;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
`;
const BoxCheckSuccess = styled.div`
  padding: 40px 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 20vh;
  left: 50%;
  transform: translatex(-50%);
  z-index: 100;
  width: 478px;
  box-sizing: border-box;
  background-color: ${color.primary.white};
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    padding: 40px 36px;
    top: 21.56vh;
    width: 334px;
  }
`;

const WrapperModal = styled.div`
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 101;
`;

const ButtonSubmit = styled(Button)`
  border-radius: 40px;
`;
const GroupButton = styled.div`
  background-color: ${color.primary.blue};
  text-align: center;
  padding: 30px 0;
`;

const FileLable = styled.span`
  box-sizing: border-box;
  width: 100%;
  padding: 20px 26px;
  border: 1px dashed rgba(35, 35, 39, 0.5);
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${color.netraul.black100};
  span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
  }
`;

const FileIcon = styled.img`
  margin-left: 12px;
`;
const SpanLabelCheckboxDesc = styled.span`
  font-size: 14px;
  line-height: 22px;
`;
const SpanLabelCheckboxTitle = styled.span`
  cursor: pointer;
  font-size: 16px;
  line-height: 26px;
  display: block;
  font-weight: bold;
  color: ${color.netraul.black100};
`;
const GroupCheckbox = styled.div`
  display: inline-flex;
  align-items: center;
  width: 33%;
  margin-bottom: 16px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    width: 50%;
    box-sizing: border-box;
    padding-right: 8px;
  }
`;
const Label = styled.label`
  font-family: Oxygen;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
`;

const TextArea = styled.textarea`
  background: ${color.primary.white};
  border-radius: 32.5px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  font-family: Oxygen;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: rgba(35, 35, 39, 0.5);
  padding: 20px 20px 20px 26px;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #2684ff;
    box-shadow: 0 0 1 #2684ff;
  }
`;
const Input = styled.input`
  &[type="text"],
  &[type="number"],
  &[type="email"] {
    background: ${color.primary.white};
    border-radius: 50px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    width: 100%;
    height: 65px;
    font-family: Oxygen;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: rgba(35, 35, 39, 0.5);
    padding-left: 26px;
    box-sizing: border-box;
    &:focus {
      outline: none;
      border-color: #2684ff;
      box-shadow: 0 0 1 #2684ff;
    }
  }
  &[type="radio"],
  &[type="checkbox"],
  &[type="file"] {
    display: none;
  }
  &[type="radio"]:checked ~ .span-radio::before {
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${color.primary.blue};
    position: absolute;
    top: 5px;
    left: 5px;
  }
  &[type="radio"]:checked ~ .span-radio {
    background-color: ${color.primary.white};
  }
  .span-checkbox {
    position: relative;
  }
  &[type="checkbox"]:checked ~ .span-checkbox {
    background-color: ${color.primary.blue};
    &::before {
      position: absolute;
      background-image: url(${CheckIcon});
      background-size: cover;
      width: 14px;
      height: 14px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: #fff;
      content: "";
    }
  }
`;
const SpanCheckbox = styled.span`
  min-width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: rgba(23, 41, 64, 0.1);
  border: 1px solid rgba(23, 41, 64, 0.3);
  position: relative;
  display: inline-block;
  margin-right: 12px;
  border-radius: 5px;
`;

const SpanLabelCheckbox = styled(Text)`
  cursor: pointer;
  color: ${color.netraul.black100};
  font-weight: 400;
  font-family: ${typography.type.primary};
`;
const GroupFormRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
    flex-direction: column;
    gap: 0;
  }
`;
const FormGroup = styled.div`
  margin-bottom: 16px;
  width: 100%;
  @media (max-width: 650px) {
    margin-bottom: 20px;
  }
`;
const MessgeError = styled.p`
  color: red;
  font-size: 14px;
  padding-left: 22px;
`;
const TitleBoxFormStar = styled.sup`
  color: red;
`;
const TitleBoxForm = styled(Text)`
  font-family: "Alternate Gothic No1 D";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: -0.015em;
  text-transform: uppercase;
  color: #232327;
  margin-bottom: 26px;
  display: block;
  @media (max-width: 1200px) {
    font-size: 36px !important;
    line-height: 36px;
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    font-weight: 500;
  }
`;
const BoxForm = styled.div`
  background-color: ${color.primary.blue10};
  padding: 30px 32px 40px;
  margin-bottom: 16px;
  @media (max-width: 1200px) {
    padding: 40px 20px;
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    margin-bottom: 40px;
  }
`;
const Form = styled.form`
  margin: auto;
  margin-top: 80px;
  max-width: 694px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    margin-top: 100px;
  }
  .StripeElement {
    background: ${color.primary.white};
    border-radius: 50px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    width: 100%;
    height: 65px;
    font-family: Oxygen;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: rgba(35, 35, 39, 0.5);
    padding: 20px 15px 20px 26px;
    box-sizing: border-box;
  }
`;
const HeaderDescription = styled(Text)`
  div {
    margin-top: 16px;
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    div {
      margin-top: 8px;
    }
    font-size: 20px !important;
    line-height: 30px;
  }
`;
const HeaderTitle = styled(Text)`
  display: block;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    font-size: 62px !important;
    line-height: 62px;
  }
`;
const HeaderWrap = styled.div`
  max-width: 876px;
  margin: auto;
`;
const WrapperSection = styled.div`
  max-width: 1500px;
  margin-top: 170px;
  margin-bottom: 150px;
  padding: 0 131px;

  @media (max-width: 1200px) {
    padding: 0 40px;
  }
  @media (max-width: 992px) {
    padding: 0 30px;
  }
  @media (max-width: 650px) {
    margin-top: 150px;
    margin-bottom: 80px;
    padding: 0 20px;
  }
  .col-6 {
    width: 50%;
    margin-bottom: 0;
    @media (max-width: 650px) {
      width: 100%;
      margin-bottom: 16px;
    }
  }
  .col-12 {
    width: 100%;
    margin: 12px 0;
  }
  .bg-blue {
    background-color: ${color.primary.blue};
  }
  .color-white {
    color: ${color.primary.white};
  }
  .bg-white-border {
    background-color: ${color.primary.white};
    border: 1px solid ${color.primary.blue40};
    @media (max-width: 1200px) {
    }
    @media (max-width: 992px) {
    }
    @media (max-width: 650px) {
      margin-top: 60px;
    }
  }
  .react-select__indicators {
    padding-right: 12px;
  }
  .react-select__option:nth-child(1) {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  .react-select__option:last-child {
    border-bottom: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .react-select__placeholder {
    color: ${color.netraul.black100};
  }
  .react-select__indicator {
    color: rgba(35, 35, 39, 0.5);
  }
  .react-select__single-value {
    color: #232327;
    font-size: 16px;
    line-height: 26px;
    font-weight: bold;
  }
  .react-select__placeholder {
    line-height: 26px;
  }
  .react-select__value-container {
    padding-left: 0;
  }
`;

FormAccelerator.defaultProps = {
  data: {
    header: {
      title: {
        raw: [
          {
            type: "preformatted",
            text: "DRAPER STARTUP HOUSE ACCELERATOR",
            spans: [],
          },
        ],
      },
      description: {
        raw: [
          {
            type: "preformatted",
            text: "Application form",
            spans: [],
          },
        ],
      },
    },
  },
};
export default FormAccelerator;
