import React from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Sidebar } from "../components/Sidebar";
import { AcceleratorFormContainer } from "../containers/AcceleratorFormContainer";
import FooterContainer from "../containers/FooterContainer";
import { Header } from "../containers/HeaderContainer";
import useApp, { withAppContext } from "../contexts/context";
import { useQueryCheckout } from "../hooks/useQueryCheckout";
import { useSiteMetadata } from "../hooks/useQuerydata";

const AcceleratorForm = () => {
  const { toggle, handleToggleLayout } = useApp();
  const { allPrismicFooter, allPrismicHeader } = useSiteMetadata();
  const { allPrismicSeo, allDataJson } = useQueryCheckout();
  // const dataJson = allDataJson.nodes;
  const dataHeader = allPrismicHeader?.nodes[0]?.data;
  const dataJson = allDataJson.nodes;
  const countriesDataNode = dataJson.find((n) => n.countries);
  const countries = countriesDataNode.countries;
  const theme = {
    bgColor: "white",
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout
        isHomepage
        toggle={toggle}
        toggleLayout={handleToggleLayout}
        header={<Header toggleLayout={handleToggleLayout} data={dataHeader} />}
        footer={<FooterContainer data={allPrismicFooter?.nodes[0]?.data} />}
        sidebar={
          <Sidebar
            toggle={toggle}
            handleToggle={handleToggleLayout}
            dataHeader={dataHeader}
            dataFooter={allPrismicFooter?.nodes[0]?.data}
          />
        }
        seo={<SEO data={allPrismicSeo?.edges[0]?.node?.data?.body} />}
      >
        <AcceleratorFormContainer countries={countries} />
      </Layout>
    </ThemeProvider>
  );
};

export default withAppContext(AcceleratorForm);
