import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import styled from "styled-components";
import FormAccelerator from "./FormAccelerator";

const promise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_TOKEN);

export const AcceleratorFormContainer = ({ countries }) => {
  return (
    <WrapperLayout>
      <Elements stripe={promise}>
        <FormAccelerator countries={countries} />
      </Elements>
    </WrapperLayout>
  );
};

const WrapperLayout = styled.div`
  margin-bottom: 100px;
  max-width: 1500px;
  margin: 0 auto;
  margin-top: 90px;
  position: relative;
`;
