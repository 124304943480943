import React, { useRef, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import IconAdd from "../../images/accelerator-add.svg";
import IconDown from "../../images/accelerator-down.png";
import IconUp from "../../images/accelerator-up.png";
import IconFactor from "../../images/accelerator-factor.png";
import IconSearch from "../../images/accelerator-search.png";
import { color } from "../../shared/style";
import useWindowSize from "../AcademyDetailContainer/useWindowSize";

const Dropdown = ({ hanleGetTag }) => {
  const data = [
    {
      id: 1,
      tag: "SAAS",
    },
    {
      id: 2,
      tag: "Blockchain/Crypto",
    },
    {
      id: 3,
      tag: "Hospitality",
    },
    {
      id: 4,
      tag: "Healthcare",
    },
    {
      id: 5,
      tag: "Marketplace",
    },
    {
      id: 6,
      tag: "CPG",
    },
    {
      id: 7,
      tag: "Travel",
    },
    {
      id: 8,
      tag: "Lifestyle/Beauty",
    },
    {
      id: 9,
      tag: "FinTech",
    },
    {
      id: 10,
      tag: "Other",
    },
  ];
  const [openDropdown, setOpenDropdown] = useState(false);
  const [listSelected, setListSelected] = useState([]);
  const [listOption, setListOption] = useState(() => [...data]);
  const [capacity, setCapacity] = useState({
    item: 0,
    status: true,
  });
  const [width] = useWindowSize();
  const outputEle = useRef();

  useLayoutEffect(() => {
    const handleCapacity = () => {
      const widthOutputEle = outputEle.current.offsetWidth;
      let count = 0;
      let i = 0;
      let listEles = Array.from(document.querySelectorAll(".itemOutput"));

      do {
        if (listEles.length > 0) {
          count += listEles[i].offsetWidth + 10; //10 is margin right of itemOutput
          ++i;
        }
      } while (i < listEles.length && count < widthOutputEle - 60); //60 is width of count element

      if (count > widthOutputEle - 60) {
        setCapacity({
          ...capacity,
          item: i,
          status: false,
        });
      } else {
        setCapacity({ ...capacity, status: true });
      }
    };
    handleCapacity();

    //handle when click dropdown outside
    if (typeof window !== "undefined") {
      window.onclick = (e) => {
        if (!e.target.closest("#container") && !e.target.closest(".item")) {
          setOpenDropdown(false);
        }
      };
    }
  }, [listSelected, width]);

  const findIndexItem = (id) => {
    return data.findIndex((item) => item.id === id);
  };

  //add a tag from data
  const handleAddTag = (id) => {
    const index = findIndexItem(id);
    setListSelected((pre) => [...pre, data[index]]);
    setListOption((pre) => pre.filter((item) => item.id !== id));
    hanleGetTag([...listSelected, data[index]]);
  };

  //detete a tag choosed
  const handleDeleteTag = (id, e) => {
    if (e) {
      e.stopPropagation();
    }
    const index = findIndexItem(id);
    setListOption((pre) => [...pre, data[index]]);
    setListSelected((pre) => pre.filter((item) => item.id !== id));
    hanleGetTag(listSelected.filter((item) => item.id !== id));
  };

  //find a tag from remaining data
  const handleFindTag = (value) => {
    let remainingData = [...data];
    for (let i = 0; i < listSelected.length; i++) {
      remainingData = remainingData.filter(
        (item) => item.id !== listSelected[i].id
      );
    }
    const listSearch = remainingData.filter((item) =>
      item.tag.toLowerCase().includes(value.toLowerCase())
    );
    setListOption([...listSearch]);
  };

  const showList = (list) =>
    list.map((item) => (
      <Selected
        className="item"
        key={item.id}
        onClick={(e) => handleDeleteTag(item.id, e)}
        style={{ marginBottom: 0 }}
      >
        <Content>{item.tag}</Content>
        <Icon className="icon" src={IconFactor}></Icon>
      </Selected>
    ));

  return (
    <ContainerDropdown id="container">
      <WrapOutput onClick={() => setOpenDropdown(!openDropdown)}>
        <Output ref={outputEle}>
          {listSelected.length > 0 ? (
            !capacity.status ? (
              <>
                {showList(listSelected).slice(0, capacity.item - 1)}
                {listSelected.length - capacity.item >= 0 && (
                  <CountItem>
                    +{listSelected.length - capacity.item + 1}
                  </CountItem>
                )}
              </>
            ) : (
              showList(listSelected)
            )
          ) : width < 650 ? (
            "My business industry categories"
          ) : (
            "My business industry categories (choose all that apply)"
          )}
        </Output>
        <Icon
          src={openDropdown ? IconUp : IconDown}
          style={{ marginRight: "2px" }}
        ></Icon>
      </WrapOutput>
      <ContainerBody display={openDropdown}>
        <ContainerOutput>
          {listSelected.map((item) => (
            <Selected
              className="itemOutput item"
              key={item.id}
              onClick={() => handleDeleteTag(item.id)}
            >
              <Content>{item.tag}</Content>
              <Icon className="icon" src={IconFactor}></Icon>
            </Selected>
          ))}
        </ContainerOutput>
        <FormSearch>
          <ImgSearch src={IconSearch}></ImgSearch>
          <Input
            type="text"
            placeholder="Find an option"
            onChange={(e) => handleFindTag(e.target.value)}
          ></Input>
        </FormSearch>
        <ContainerSelection>
          {listOption.map((item) => (
            <Option
              className="item"
              key={item.id}
              onClick={() => handleAddTag(item.id)}
            >
              <Icon className="icon" src={IconAdd}></Icon>
              <Content>{item.tag}</Content>
            </Option>
          ))}
        </ContainerSelection>
      </ContainerBody>
    </ContainerDropdown>
  );
};

const Content = styled.span`
  color: ${color.primary.blue};
  font-size: 12px;
  line-height: 22px;
  font-weight: bold;
  @media (max-width: 650px) {
    font-size: 12px;
  }
`;
const CountItem = styled.span`
  display: inline-block;
  padding: 7px 14px;
  border-radius: 20px;
  background: #e9ecf1;
  line-height: 22px;
  font-size: 12px;
  color: ${color.primary.blue};
  font-weight: bold;
`;
const Selected = styled.span`
  padding: 7px 13px;
  border-radius: 20px;
  background-color: ${color.primary.blue10};
  margin-right: 10px;
  margin-bottom: 8px;
  transition: all 0.4s ease;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box-box;
  .icon {
    margin-left: 7px;
    margin-right: 0;
    width: 0;
    transition: all 0.2s ease;
  }
  &:hover {
    color: ${color.secondary.danube};
    background-color: ${color.primary.white};
    border-color: ${color.secondary.danube} !important;
  }
  &:hover .icon {
    width: 14px;
  }
`;
const Icon = styled.img`
  width: 14px;
  max-height: 14px;
  margin-right: 7px;
`;

const Option = styled.span`
  padding: 7px 13px;
  border-radius: 20px;
  background-color: ${color.primary.blue10};
  margin-right: 10px;
  margin-bottom: 8px;
  transition: all 0.4s ease;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  &:hover {
    color: ${color.secondary.danube};
    background-color: ${color.primary.white};
    border-color: ${color.secondary.danube} !important;
  }
  &:hover .icon {
    filter: invert(54%) sepia(33%) saturate(803%) hue-rotate(167deg)
      brightness(91%) contrast(88%);
  }
`;
const ContainerSelection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
`;
const Input = styled.input`
  background: ${color.primary.white};
  border-radius: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(35, 35, 39, 0.2);
  width: 100%;
  height: 42px;
  font-family: Oxygen;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: rgba(35, 35, 39, 0.5);
  padding-left: 34px;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #2684ff;
    box-shadow: 0 0 1 #2684ff;
  }
`;
const ImgSearch = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  z-index: 10;
  width: 14px;
`;
const FormSearch = styled.div`
  margin-top: 20px;
  position: relative;
`;
const ContainerOutput = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(35, 35, 39, 0.2);
`;
const ContainerBody = styled.div`
  width: 100%;
  box-sizing: border-box;
  visibility: ${(props) => (props.display ? "visible" : "hidden")};
  opacity: ${(props) => (props.display ? 1 : 0)};
  background-color: ${color.primary.white};
  border-radius: 32px;
  padding: 20px;
  top: 75px;
  position: absolute;
  z-index: 10;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
`;
const Output = styled.div`
  color: ${color.netraul.black100};
  width: 100%;
`;
const WrapOutput = styled.div`
  cursor: pointer;
  background: ${color.primary.white};
  border-radius: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  width: 100%;
  height: 65px;
  font-family: Oxygen;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: rgba(35, 35, 39, 0.5);
  padding: 0 22px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 650px) {
    pading-left: 20px;
  }
`;
const ContainerDropdown = styled.div`
  position: relative;
`;
export default Dropdown;
